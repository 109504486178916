import * as THREE from 'three';
import { Suspense, useEffect, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { Logo2 } from './Logo2';
import StarField from './StarField';
import { EffectComposer, Bloom, Noise } from '@react-three/postprocessing';
import { KernelSize, BlendFunction } from 'postprocessing';

const App = () => {
  const [luminanceThreshold, setLuminanceThreshold] = useState(9);
  const [luminanceSmoothing, setLuminanceSmoothing] = useState(39);
  const [intensity, setIntensity] = useState(14);

  useEffect(() => {
    console.table({ luminanceThreshold, luminanceSmoothing, intensity });
  }, [luminanceThreshold, luminanceSmoothing, intensity]);

  return (
    <div className="wrapper">
      <Canvas
        camera={{
          fov: 80,
          position: [5, 12, 25],
        }}
        className="canvas"
      >
        <Scene />
        <EffectComposer>
          <Bloom
            luminanceThreshold={luminanceThreshold / 100}
            luminanceSmoothing={luminanceSmoothing / 100}
            intensity={intensity / 20}
            mipmapBlur
          />
          <Noise opacity={0.04} />
        </EffectComposer>
      </Canvas>
      {/* <div className="controls">
        <label>luminanceTreshold</label>
        <input type={"range"} min={0} max={100} value={luminanceThreshold} onChange={e => setLuminanceThreshold(parseInt(e.target.value))} />
        <label>luminanceSmoothing</label>
        <input type={"range"} min={0} max={100} value={luminanceSmoothing} onChange={e => setLuminanceSmoothing(parseInt(e.target.value))} />
        <label>intensity</label>
        <input type={"range"} min={0} max={100} value={intensity} onChange={e => setIntensity(parseInt(e.target.value))} />
      </div> */}
      <div id="colorReference-logo"></div>
      <div id="colorReference-star"></div>
    </div>
  );
};

export default App;

const Scene = () => {
  const [logoRotation, setLogoRotation] = useState<[number, number, number]>([
    0, 0, 0,
  ]);

  const [starsRotation, setStarsRotation] = useState<[number, number, number]>([
    0, 0, 0,
  ]);

  const [groupRotation, setGroupRotation] = useState<[number, number, number]>([
    0, 0, 0,
  ]);

  const [logoColor, setLogoColor] = useState<string>('rgb(238, 150, 0)');
  const [starColor, setStarColor] = useState<string>('rgb(255, 239, 211)');

  useEffect(() => {
    const clock = new THREE.Clock();
    let request: number = 0;

    // START MOUSE ANIMATION
    let mouseX = 0;
    let mouseY = 0;

    const getMousePos = (event: MouseEvent) => {
      mouseX = event.clientX;
      mouseY = event.clientY;
    };

    document.addEventListener('mousemove', getMousePos);

    let accelAmount = 0.04;
    let delayY = 0;
    let delayX = 0;
    // END MOUSE ANIMATION

    // START COLOR ANIMATION
    const handleMessage = (message: MessageEvent<any>) => {
      switch (message.data) {
        case 'hover':
          document.getElementById(
            'colorReference-logo'
          )!.style.backgroundColor = 'rgb(0, 255, 0)';
          document.getElementById(
            'colorReference-star'
          )!.style.backgroundColor = 'rgb(204, 255, 204)';
          break;
        default:
          document.getElementById(
            'colorReference-logo'
          )!.style.backgroundColor = 'rgb(238, 150, 0)';
          document.getElementById(
            'colorReference-star'
          )!.style.backgroundColor = 'rgb(255, 239, 211)';
          break;
      }
    };

    window.addEventListener('message', handleMessage);
    // END COLOR ANIMATION

    // ANIMATION FUNCTION
    const animate = () => {
      const elapsedTime = clock.getElapsedTime();
      setLogoRotation((p) => [p[0], 0.35 * elapsedTime, p[2]]);
      setStarsRotation((p) => [0.001 * elapsedTime, 0.02 * elapsedTime, p[2]]);

      const targetY = (mouseY / window.innerHeight) * Math.PI * 2 * (20 / 360);
      const targetX = (mouseX / window.innerWidth) * Math.PI * 2 * (20 / 360);

      delayY += (targetY - delayY) * accelAmount;
      delayX += (targetX - delayX) * accelAmount;

      setGroupRotation([-delayY, delayX, 0]);

      let colorStyleLogo = window.getComputedStyle(
        document.getElementById('colorReference-logo')!,
        null
      );
      let colorStyleStar = window.getComputedStyle(
        document.getElementById('colorReference-star')!,
        null
      );

      setLogoColor(
        colorStyleLogo.getPropertyValue('background-color') || 'rgb(238, 150, 0)'
      );
      setStarColor(
        colorStyleStar.getPropertyValue('background-color') || 'rgb(238, 150, 0)'
      );

      request = requestAnimationFrame(animate);
    };
    animate();

    return () => {
      cancelAnimationFrame(request);
      document.removeEventListener('mousemove', getMousePos);
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <Suspense fallback={null}>
      <group rotation={groupRotation}>
        <Logo2 rotation={logoRotation} color={new THREE.Color(logoColor)} />
        <StarField rotation={starsRotation} color={new THREE.Color(starColor)} />
      </group>
    </Suspense>
  );
};
