import { useMemo } from "react";
import { BufferAttribute, TextureLoader } from "three";

const StarField = ({ rotation, color }: { rotation: [number, number, number]; color: THREE.Color }) => {
  const particlesCount = 3500;
  const multiplier = 75;
  const points = useMemo(() => {
    const p = new Array(particlesCount * 3)
      .fill(0)
      .map((v) => (0.5 - Math.random()) * multiplier);
    return new BufferAttribute(new Float32Array(p), 3);
  }, [particlesCount]);

  const star = useMemo(() => {
    const loader = new TextureLoader();
    return loader.load("/star2.png");
  }, []);

  return (
    <points rotation={rotation}>
      <bufferGeometry>
        <bufferAttribute attach={"attributes-position"} {...points} />
      </bufferGeometry>
      <pointsMaterial
        size={0.075}
        color={color}
        sizeAttenuation={true}
        map={star}
        transparent
      />
    </points>
  );
};

export default StarField;
