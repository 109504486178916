import { useGLTF } from "@react-three/drei";

export function Logo2(props) {
  const { nodes, materials } = useGLTF("/logo2.glb");

  return (
    <group
      {...props}
      dispose={null}
      rotation={props.rotation}
      position={[window.innerWidth >= 768 ? 12 : 0, window.innerWidth >= 768 ? -5 : 2, 0]}
    >
      <mesh geometry={nodes.Path.geometry} scale={window.innerWidth >= 768 ? 1 : 0.6}>
        <meshBasicMaterial attach={"material"} wireframe color={props.color} />
      </mesh>
    </group>
  );
}

useGLTF.preload("/logo2.glb");
